<template>
    <b-container>
        <b-row class="text-center" align-v="center">
            <b-col cols="12" lg="6" class="mb-5 mb-lg-0">
                <b-img src="@/assets/contact.jpg" fluid-grow />
            </b-col>
            <b-col cols="12" lg="6" style="text-align: center;">
                <p>
                    Je suis à votre disposition pour créer votre accompagnement
                    sur mesure. <br />
                    Vous voulez plus d'informations, un renseignement, ou tout
                    simplement prendre rendez-vous.
                </p>
                <p>
                    Je vous invite à me contacter au <br class="d-md-none" />{{ mobileNumber }}
                </p>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col cols="10" sm="11">
                <h3>Ou par mail</h3>
            </b-col>
        </b-row>
        <b-row class="mt-3 mb-5">
            <b-col cols="12">
                <ValidationObserver ref="formObs">
                    <b-form>
                        <b-form-group ref="fname" label="Prénom" label-for="input_fname">
                            <ValidationProvider name="fname" :rules="{ required: 'prénom' }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_fname" type="text" placeholder="Prénom" v-model="user.fname" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group id="name" label="Nom" label-for="input_name">
                            <ValidationProvider name="name" :rules="{ required: 'nom' }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_name" type="text" placeholder="Nom" v-model="user.name" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group id="mail" label="Mail" label-for="input_mail">
                            <ValidationProvider name="mail" :rules="{
                                required: 'adresse mail',
                                email: true,
                            }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_mail" placeholder="Adresse mail" v-model="user.mail" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group ref="tel" label="Téléphone" label-for="input_tel">
                            <ValidationProvider name="tel" :rules="{
                                required: 'numéro de téléphone',
                                phone: true,
                            }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_tel" placeholder="xx.xx.xx.xx.xx" v-model="user.tel" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group ref="obj" label="Objet" label-for="input_obj">
                            <ValidationProvider name="obj" :rules="{ required: 'objet' }" v-slot="{ errors, valid }">
                                <b-form-input ref="input_obj" type="text" placeholder="Sujet à aborder"
                                    v-model="mailobj.object" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group ref="msg" label="Message" label-for="input_msg">
                            <ValidationProvider name="msg" :rules="{ required: 'message' }" v-slot="{ errors, valid }">
                                <b-form-textarea ref="input_msg" placeholder="Votre message" style="min-height: 200px;"
                                    v-model="mailobj.content" />
                                <b-form-invalid-feedback :state="valid">
                                    <span>{{ errors[0] }}</span>
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <b-button variant="primary" class="form_btn" @click="showResetModal">
                            <b-icon-trash /> Effacer
                        </b-button>
                        <b-button variant="secondary" class="form_btn float-right" @click="sendMail">
                            <b-icon-check2-circle /> Envoyer
                        </b-button>
                    </b-form>
                </ValidationObserver>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapState } from 'vuex';

import {
    BIcon,
    BIconTelephoneFill,
    BIconEnvelopeFill,
    BIconFacebook,
    BIconInstagram,
    BIconX,
    BIconTrash,
    BIconCheck2Circle,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    name: 'Contact',
    components: {
        ValidationObserver,
        ValidationProvider,
        BIcon,
        BIconTelephoneFill,
        BIconEnvelopeFill,
        BIconFacebook,
        BIconInstagram,
        BIconX,
        BIconTrash,
        BIconCheck2Circle,
    },
    data: function () {
        return {
            not_set: true,
            mail: false,
            tel: false,
            user: {
                fname: '',
                name: '',
                tel: '',
                mail: '',
            },
            mailobj: {
                from: this.$store.state.donotanswmail,
                to: this.$store.state.mailadd,
                object: '',
                content: '',
            },
        };
    },
    computed: {
        ...mapState([
            'mobileNumber',
            'facebook',
            'instagram',
            'domainname',
            'protocol',
        ]),
    },
    methods: {
        /**
         * Hide the question about contact media and show phone number.
         */
        telPicked() {
            this.not_set = false;
            this.tel = true;
        },
        /**
         * Hide the question about contact media and show the mail form.
         */
        mailPicked() {
            this.not_set = false;
            this.mail = true;
        },
        /**
         * Reset display, displaying question about contact way.
         */
        close() {
            this.not_set = true;
            this.mail = false;
            this.tel = false;
        },
        /**
         * Display modal confirmation. If confirmed, run passed method as
         * callback. Else, does nothing.
         */
        showConfirmModal(message, variant, callback) {
            this.$bvModal
                .msgBoxConfirm(message, {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: variant,
                    okTitle: 'Je confirme',
                    cancelTitle: 'Annuler',
                    centered: true,
                })
                .then((value) => {
                    if (value && callback != undefined) {
                        callback();
                    }
                });
        },
        showResetModal() {
            this.showConfirmModal(
                'Êtes-vous sûr(e) de vouloir effacer le formulaire ?',
                'danger',
                this.reset
            );
        },
        /**
         * Ask confirmation about form deletion. If it is confirmed, just erase
         * all fields. Else does nothing.
         */
        reset() {
            this.user.fname = '';
            this.user.name = '';
            this.user.mail = '';
            this.user.tel = '';
            this.mailobj.content = '';
            this.mailobj.object = '';
        },
        /**
         * Check values about their consistency.
         * Ask confirmation about sending mail with fields' value.
         * If confirmed, send mail.
         * Else, let the user reset values.
         */
        sendMail() {
            this.$refs.formObs.validate().then((success) => {
                if (success) {
                    this.showConfirmModal(
                        'Envoyer un mail à partir des informations fournies ?',
                        'primary',
                        this.makeAxiosRequest
                    );
                } else {
                    this.$bvModal.msgBoxOk(
                        'Les données fournies dans le formulaire ne sont pas correctement renseignées.',
                        {
                            title: 'Données du formulaire mal renseignées',
                            okVariant: 'primary',
                            centered: true,
                        }
                    );
                }
            });
        },
        userDesc() {
            let desc = '=== CLIENT ===\n';
            desc += '- Prénom :    ' + this.user.fname + '\n';
            desc += '- Nom :       ' + this.user.name + '\n';
            desc += '- Téléphone : ' + this.user.tel + '\n';
            desc += '- Mail :      ' + this.user.mail + '\n';
            desc += '=============\n\n';

            return desc;
        },
        /**
         * Send request to send mail.
         */
        makeAxiosRequest() {
            let axios = require('axios');

            const content = this.userDesc() + this.mailobj.content;

            let data = JSON.stringify({
                from: this.mailobj.from,
                to: this.mailobj.to,
                object: this.mailobj.object,
                content: content,
            });

            let config = {
                method: 'post',
                url: this.protocol + this.domainname + '/sendmail',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    this.$bvModal
                        .msgBoxOk('Votre mail a bien été envoyé', {
                            title: "Confirmation d'envoi",
                            okVariant: 'primary',
                            centered: true,
                        })
                        .then(function () {
                            location.reload();
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
h3 {
    color: var(--secondary);
}

.form_btn {
    min-width: 10em;
}

.btn svg {
    font-size: 25px;
}

.btn .fa-facebook {
    color: var(--primary);
}

.btn-bg-transparent:hover {
    background-color: var(--secondary);
}
</style>
